document.addEventListener("turbolinks:load", function() {
  var AvatarCropper, jCrop,
    __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  jCrop = {
    initialized: false
  };

  AvatarCropper = (function() {
    function AvatarCropper() {
      this.updatePreview = __bind(this.updatePreview, this);
      this.update = __bind(this.update, this);
      this.calculateAspectRatioFit = __bind(this.calculateAspectRatioFit, this);
      this.upload_file = __bind(this.upload_file, this);
      var current_class, jscrop_api;
      current_class = this;
      jscrop_api = void 0;
      $('#cropbox').Jcrop({
        aspectRatio: 1,
        setSelect: [0, 0, 500, 500],
        onSelect: this.update,
        onChange: this.update
      }, function() {
        var jcrop_api;
        jCrop.initialized = true;
        jcrop_api = this;
        $('#user-photo').on('change', function(input) {
          jcrop_api.destroy();
          console.log('1111');
          return current_class.upload_file(input);
        });
      });
    }

    AvatarCropper.prototype.upload_file = function(input) {
      var current_class, reader;
      if (input.target.files && input.target.files[0]) {
        current_class = this;
        reader = new FileReader();
        reader.onload = function(e) {
          var image;
          image = new Image();
          image.src = reader.result;
          $('.user-upload-photo-preview img, .user-photo img').attr('src', e.target.result);
          return image.onload = function(e) {
            var result;
            result = current_class.calculateAspectRatioFit(image.width, image.height, 500, 500);
            $('#cropbox').css("width", result["width"] + "px");
            $('#cropbox').css("height", result["height"] + "px");
            return new AvatarCropper();
          };
        };
        return reader.readAsDataURL(input.target.files[0]);
      }
    };

    AvatarCropper.prototype.calculateAspectRatioFit = function(srcWidth, srcHeight, maxWidth, maxHeight) {
      var ratio;
      ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      return {
        width: srcWidth * ratio,
        height: srcHeight * ratio
      };
    };

    AvatarCropper.prototype.update = function(coords) {
      $('#user_crop_x').val(coords.x);
      $('#user_crop_y').val(coords.y);
      $('#user_crop_w').val(coords.w);
      $('#user_crop_h').val(coords.h);
      return this.updatePreview(coords);
    };

    AvatarCropper.prototype.updatePreview = function(coords) {
      return $('.user-photo--preview img, .user-photo img').css({
        width: Math.round(190 / coords.w * $('#cropbox').width()) + 'px',
        height: Math.round(190 / coords.h * $('#cropbox').height()) + 'px',
        marginLeft: '-' + Math.round(190 / coords.w * coords.x) + 'px',
        marginTop: '-' + Math.round(190 / coords.h * coords.y) + 'px'
      });
    };

    return AvatarCropper;

  })();

  $('#upload-photo').on('show.bs.modal', function(e) {
    if (!jCrop.initialized) {
      return new AvatarCropper();
    }
  });

});
