document.addEventListener("turbolinks:load", function() {
  $('.ratingable-rating-average, #ratingable-rating-average').rating({size: 'xs', showCaption: false, showClear: false, readonly: true})

  $('#ratingable-rating').rating({step: 1, size: 'xs', showCaption: false, showClear: false});

  $('#ratingable-rating').on('rating:change', function(event, value, caption) {
    if ($('#personal-page').length == 1) {
      $.ajax({
        url: event.currentTarget.dataset.path,
        method: 'PUT',
        dataType: 'script',
        data: { rating: value }
      }).fail(function(response) {
        $(event.currentTarget).rating('reset');
      })
    } else {
      $('#signup').modal('show')
    }
  });

});
