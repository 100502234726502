document.addEventListener("turbolinks:load", function() {
  if ($('#personal-page').length == 0) {
    $("#home-contact input, #home-contact textarea").on('click', function(e) {
      $.toast({
        title: "Чтобы отправить сообщение, Вы должны быть авторизованы на сайте под своим логином и паролем!",
        type: 'danger',
        delay: 10000
      });
    });
  }
});
