import consumer from "./consumer"

consumer.subscriptions.create("AppearancesChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    var user = $(".user-" + data['user_id']['$oid']);
    user.toggleClass('online', data['online']);
  }
});
