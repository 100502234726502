document.addEventListener("turbolinks:load", function() {
  $('.comment-footer-answer').on('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var commentBlock = $(this).closest('.comment-block');
    var form = $(commentBlock).find('form');
    $('html, body').animate({
      scrollTop: $(form).offset().top
    }, 200);

    $(commentBlock).find('textarea, #comment_body').focus();
    $(commentBlock).find('.answerable-parent-id').val($(this).data('answerable-id'));
  });
});
