import consumer from "./consumer"

window.chatroomChannel = consumer.subscriptions.create("ChatroomsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var active_chatroom = $("[data-behavior='messages'][data-chatroom-id='"+ data.chatroom_id +"']");

    if (active_chatroom.length > 0) {
      if (document.hidden) {
        lastReadChannel.update(data.chatroom_id);
      }
      $('.user-dialog-items').append(data.message);
      $('.user-dialog-items')[0].scrollTop = $('.user-dialog-items')[0].scrollHeight;
    }
    // Called when there's incoming data on the websocket for this channel
  },

  send_message(chatroom_id, message) {
    this.perform("send_message", {chatroom_id: chatroom_id, body: message})
  }
});
