document.addEventListener("turbolinks:load", function() {
  $('#friend-search').on('keypress', function(){
    if ($(this).val().length > 2) {
      $.ajax({
        url: "/users/",
        data: { q: $(this).val() },
        success: function(data) {}
      })
    }
  });
});
