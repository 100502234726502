(function(b) {
  b.toast = function(a, h, g, l, k) {
    b("#toast-container").length || (b("body").prepend('<div id="toast-container" aria-live="polite" aria-atomic="true"></div>'), b("#toast-container").append('<div id="toast-wrapper"></div>'));
    var c = "",
      d = "",
      e = "text-muted",
      f = "",
      m = "object" === typeof a ? a.title || "" : a || "Notice!";
    h = "object" === typeof a ? a.subtitle || "" : h || "";
    g = "object" === typeof a ? a.content || "" : g || "";
    k = "object" === typeof a ? a.delay || 3E3 : k || 3E3;
    switch ("object" === typeof a ? a.type || "" : l || "info") {
      case "info":
        c = "bg-info";
        f = e = d = "text-white";
        break;
      case "success":
        c = "bg-success";
        f = e = d = "text-white";
        break;
      case "warning":
      case "warn":
        c = "bg-warning";
        f = e = d = "text-white";
        break;
      case "error":
      case "danger":
        c = "bg-danger", f = e = d = "text-white"
    }
    a = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="' + k + '">' + ('<div class="toast-header ' + c + " " + d + '">') + ('<strong class="mr-auto">' + m + "</strong>");
    a += '<small class="' + e + '">' + h + "</small>";
    a += '<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">';
    a += '<span aria-hidden="true" class="' + f + '">&times;</span>';
    a += "</button>";
    a += "</div>";
    "" !== g && (a += '<div class="toast-body">', a += g, a += "</div>");
    a += "</div>";
    z = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">'
    z += '<div class="toast-header bg-info text-white">'
    z += '<strong class="mr-auto">Toast</strong>'
    z += '<small class="text-white">11 mins ago</small>'
    z += '<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">'
    z += '<span aria-hidden="true" class="text-white">&times;</span>'
    z += '</button>'
    z += '</div>'
    z += '<div class="toast-body">Hello, world! This is a toast message.</div>'
    z += '</div>'

    b("#toast-wrapper").append(a);
    b("#toast-wrapper .toast:last").toast("show")
  }
})(jQuery);
