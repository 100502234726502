require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap/dist/js/bootstrap")
require("owl.carousel/dist/owl.carousel")

require("packs/main")
require("packs/video")
require("packs/posts")
require("packs/friends")
require("packs/load-image")
require("packs/toast")
require("packs/notifications")
require("packs/star-rating")
require("packs/rating")
require("packs/comments")
require("packs/chatrooms")
require("packs/jquery.Jcrop")
require("packs/avatar")
require("packs/pagination")
require("packs/contact")
require("packs/turbolinks_events")
require("packs/datepicker/version")
require("packs/datepicker/datepicker-ru")
require("packs/datepicker/datepicker")
require("packs/jquery-ui")

import "@fortawesome/fontawesome-free/js/all";

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
