document.addEventListener("turbolinks:load", function() {
  $('.datepicker').datepicker({ dateFormat: 'dd-mm-yy', changeYear:true, yearRange: "1900:" + (new Date()).getFullYear() });

  window.loadImageable = function loadImageable(input) {
    var targetId = input.target.id;
    if (input.target.files && input.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        $('figure.news-image img').attr('src', e.target.result);
        $('figure.news-image svg').toggleClass('d-none');
      }
      reader.readAsDataURL(input.target.files[0]);
    }
  }

  // PROFILE: toggle contacts between read and edit mode
  $(".btn--user-edit").on('click', function(e){
    e.preventDefault();
    $(".user-contact-edit, .user-contact-view").toggleClass('d-none');
  });

  $(".navbar-toggler").on('click', function(){
    $('body').toggleClass('menu-opened');
  });

  window.likeAction = function likeAction(event) {
    event.preventDefault();
    event.stopPropagation();
    var element = event.target || event.srcElement;

    var target;
    if ($(element).is('i, span, svg')) {
      target = $(element).parent();
    } else {
      target = $(element);
    }

    if ($('#personal-page').length == 1) {
      $.ajax({
        url: $(target).attr('href'),
        method: "POST",
        dataType: "script"
      });
    } else {
      $('#signup').modal('show')
    }
  }

  // Add field
  $('.link_to_add_fields').on("click", function(e){
    e.preventDefault();
    var link = $(this);
    var association = $(this).data("association");
    var content = $(this).data("content");
    add_fields(link, association, content);

    if ($(this).is('[data-association="educations"]')) {
      studyConnectToFiled('.user-study', '.user-study-container');
    }
  });

  $('body').on("click", '.link_to_remove_fields', function(e){
    e.preventDefault();
    $(this).closest('.input-group').remove();
  });

  function add_fields(link, association, content) {
    var new_id = new Date().getTime();
    var regexp = new RegExp("new_" + association, "g");
    $(link).before(content.replace(regexp, new_id));
  }

  if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))) {
    $(".testimonials-carousel").owlCarousel({
      items: 1,
      slide: 1,
      loop: false,
      center: false,
      nav: true,
      rewindNav: true,
      dots: true,
      margin: 50,
      /*responsive: {
        0: {
          items: 1
        },
        415: {
          items: 2
        },
        768: {
          items: 3
        }
      },*/
      navText: [
        '<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><polygon fill="" points="36.238,44.061 24.271,32.226 36.156,20.115 37.996,21.918 27.907,32.199 38.05,42.229 "/></svg>',
        '<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><polygon fill="" points="36.238,44.061 24.271,32.226 36.156,20.115 37.996,21.918 27.907,32.199 38.05,42.229 "/></svg>'
      ]
    });

  }


  /*if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {

    $(".news-carousel, .contests-carousel, .voting-carousel").owlCarousel({
      items: 1,
      slide: 1,
      loop: false,
      center: true,
      nav: false,
      rewindNav: true,
      dots: false,
      navText: [
        '<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><polygon fill="" points="36.238,44.061 24.271,32.226 36.156,20.115 37.996,21.918 27.907,32.199 38.05,42.229 "/></svg>',
        '<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><polygon fill="" points="36.238,44.061 24.271,32.226 36.156,20.115 37.996,21.918 27.907,32.199 38.05,42.229 "/></svg>'
      ]
    });

  }*/

  if ( (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && $('.mobile-category').length > 0) {
    $('.mobile-category select').on('change', function(e){
      $('.active').removeClass('active');
      $('.mobile-category span').text($(this).children('option:selected').text());
      var target = $(this).children('option:selected').val();
      $("." + target).addClass('active');
    });
  }

  if ( (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && $('.mobile-nav').length > 0) {
    $('.mobile-nav select').on('change', function(e){
      $('.active').removeClass('active');
      $('.mobile-nav span').text($(this).children('option:selected').text());
      var target = $(this).children('option:selected').val();
      $("." + target).addClass('active');
    });
  }


  function studyConnectToFiled(element, container) {
    let containerTarget = $(element).closest(container);
    fetch("/world-universities.csv")
      .then(response => response.text())
      .then(function(text) {
        $( element ).autocomplete({
          appendTo: containerTarget,
          source: function(request, response) {
            var results = $.ui.autocomplete.filter(text.split(/\r|\n/), request.term);
            response(results.slice(0, 10));
          }
        });
      });
  }

  if ($(".user-study").length > 0) {
    studyConnectToFiled('.user-study', '.user-study-container');
  }

/*
  function textareaAutoResize(element){

    var textarea = document.querySelector(element),
      textareaHelper = document.createElement('div'),
      content = null;

    textareaHelper.classList.add('textarea-helper');

    textarea.addEventListener('input', function() {
      textarea.parentNode.appendChild(textareaHelper);
      textarea.style.resize = 'none';
      textarea.style.overflow = 'hidden';
      content = textarea.value;
      content = content.replace(/\n/g, '<br>');
      textareaHelper.innerHTML = content;
      textareaHelper.style.visibility = 'hidden';
      textareaHelper.style.display = 'block';
      textarea.style.height = textareaHelper.offsetHeight + 'px';
      textareaHelper.style.visibility = 'visible';
      textareaHelper.style.display = 'none';
    });

  }

  $(document).ready(function($){
    if ($('.answerable-body').length > 0) {
      textareaAutoResize('.answerable-body');
    }
  });*/

  $('textarea').each(function () {
    this.setAttribute('style', 'height:' + (this.scrollHeight) + 'px;overflow-y:hidden;');
  }).on('input', function () {
    this.style.height = 'auto';
    this.style.height = (this.scrollHeight) + 'px';
  });


});
