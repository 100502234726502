document.addEventListener("turbolinks:before-cache", function() {
  if ($('.star-rating').length > 0) {
    $('.star-rating').rating('destroy');
  }

  $.map(CKEDITOR.instances,function(ob,ind){
    ob.destroy();
  });

  $(".testimonials-carousel").owlCarousel('destroy');
})
