document.addEventListener("turbolinks:load", function() {
  $('#attack-link').on('click', function() {
    $('.user-post-attachment').html('');
    if ($("input#post_links_attributes_0_path").val() != "") {
      var url = $("input#post_links_attributes_0_path").val();
      $('.user-post-attachment').append("<a href='"+url+"'>"+(new URL(url)).hostname+"</a>");
    }
    $('#post-attachment-link').modal('hide');
  });
})
