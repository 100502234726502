document.addEventListener("turbolinks:load", function() {
  if ($('#infinite-items').length > 0) {
    $(window).on('scroll', function() {
      if ($(window).data('ajaxready') == false) {return}
      var more_posts_url = $('#infinite-scrolling .pagination .next a').attr('href');

      if (($(window).scrollTop() + $(window).height()) > ($(document).height() - 500)) {
        if (more_posts_url) {
          $(window).data('ajaxready', false)
          $.getScript(more_posts_url);
        }
      }
    })
  }
});
