document.addEventListener("turbolinks:load", function() {
  $("[data-behavior='notification-link']").on("click", function() {
    $.ajax({
      url: "/notifications/mark_as_read",
      dataType: "JSON",
      method: "POST",
      success: function() {
        $("[data-behavior='unread-count']").text(0)
      }
    })
  });
});
