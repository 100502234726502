function handleVisibilityChange() {
  var chatroom = $("[data-behavior='messages']").attr('data-chatroom-id');
  if (chatroom) {
    lastReadChannel.update(chatroom);
  }
}

document.addEventListener("turbolinks:load", function() {
 $(document).on("click", handleVisibilityChange())
  if ($("#new_message").length > 0) {
    $('.user-dialog-items')[0].scrollTop = $('.user-dialog-items')[0].scrollHeight;
  }

  $("#new_message").on("submit", function(e) {
    e.preventDefault()

    var chatroom_id = $("[data-behavior='messages']").data('chatroom-id');
    var body = $("#message-body");

    if (body.val() != "") {
      chatroomChannel.send_message(chatroom_id, body.val())

      body.val("")
    }
  });

  $('#send-message').on('shown.bs.modal', function (event) {
    $('<input>').attr({
      type: 'hidden',
      id: 'chatroom_recipient_id',
      name: 'chatroom[recipient_id]',
      value: $(event.relatedTarget).data('recipient-id'),
    }).appendTo('form#new_chatroom');
  })
})
